import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';

const routes: Routes = [
  {
    path: 'header',
    component: HeaderComponent
  },
  {
    path: "customer",
    loadChildren: () =>
      import("../app/modules/customers/customers.module").then((m) => m.CustomersModule),
  },
  { 
    path: 'auth', 
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) 
  },
  {
    path: "self-service",
    loadChildren: () =>
      import("../app/modules/self-service/self-service.module").then((m) => m.SelfServiceModule),
  },
  { path: 'shared', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },
  { 
    path: "**", 
    redirectTo: "/auth/login" 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
