import { AuthService } from './../../auth/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceService {
  showLogOut: boolean = false;
  constructor(private http: HttpClient, private authService: AuthService) {}

  refreshCustomer(payload: any) {
    return this.http.post(
      environment.base_eru_routes_url + 'artfine/func/refresh_customer/',
      payload
    );
  }
  fetchCustomer(payLoad: any) {
    return this.http.post(
      environment.base_eru_url +
        'store/artfine/myquery/execute/fetch_customer_basic',
      payLoad
    );
  }
  fetchCustomerGSTIN(payLoad: any) {
    return this.http.post(
      environment.base_eru_url +
        'store/artfine/myquery/execute/fetch_customer_gstins_basic',
      payLoad
    );
  }
  generategstotp(payLoad: any) {
    return this.http.post(
      environment.base_eru_routes_url + 'artfine/func/generategstotp',
      {
        otpdata: payLoad,
      }
    );
  }
  generategstsession(payLoad: any) {
    return this.http.post(
      environment.base_eru_routes_url + 'artfine/func/generategstsession',
      {
        otpdata: payLoad,
      }
    );
  }
  save_customer_gst_consent(payload: any) {
    return this.http.post(
      environment.base_eru_url +
        'store/artfine/myquery/execute/save_customer_gst_consent',
      {
        ...payload,
      }
    );
  }
  upload(data: any) {
    //{{base_eru_files_url}}/files/artfine/co_docs/upload
    return this.http.post(
      environment.base_eru_files_url + 'files/artfine/co_docs/upload',
      data
    );
  }
  save_customer_bank_st(payload: any) {
    return this.http.post(
      environment.base_eru_routes_url +
        'artfine/func/cygnet_bankst_save_upld',
      payload
    );
  }
  registerCutsomer(payLoad: any) {
    return (
      this,
      this.http.post(
        environment.base_eru_routes_url + 'artfine/func/register_customer',
        payLoad
      )
    );
  }
  fetch_docTypes(payload: any) {
    return this.http.post(
      environment.base_eru_url +
        'store/artfine/myquery/execute/fetch_doc_types',
      payload
    );
  }

  setToken(token: any) {
    console.log(token);
    this.authService.setTokens(token);
    this.authService
      .fetchUser(token.access_token, token.id_token)
      .subscribe((res: any) => {
        localStorage.setItem('user', JSON.stringify(res));
        this.authService.setUser(res);
      });
  }

  save_customer_docs(payload: any) {
    return this.http.post(
      environment.base_eru_url +
        'store/artfine/myquery/execute/save_customer_docs',
      payload
    );
  }
  fetchBankStatement(payload: any) {
    return this.http.post(
      environment.base_eru_url + 'store/artfine/myquery/execute/fetch_bank_st',
      payload
    );
  }
  checkCustomerExists(payload: any) {
    return this.http.post(
      environment.base_eru_url + 'store/artfine/myquery/execute/check_customer',
      payload
    );
  }
  showCheckList = false;
  routeEnabled = false;
  selfServiceRoutes: any[] = [
    {
      path: '/self-service',
      index: 0,
      label: 'Checklist',
      show: this.showCheckList,
      enabled: true,
    },
    {
      path: '/self-service/company',
      index: 1,
      label: 'Company Details',
      show: true,
      enabled: true,
    },
    {
      path: '/self-service/gst',
      index: 2,
      label: 'GST Details',
      show: true,
      enabled: false,
    },
    {
      path: '/self-service/bank',
      index: 3,
      label: 'Bank Details',

      show: true,
      enabled: false,
    },
    {
      path: '/self-service/other',
      index: 4,
      label: 'Other Documents',
      show: true,
      enabled: false,
    },
  ];

  downloadb64(payload: any) {
    return this.http.post(
      environment.base_eru_files_url + 'files/artfine/co_docs/downloadb64',
      payload
    );
  }
}
