import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Logout } from 'src/app/store/actions/logout.action';
import { Store } from '@ngrx/store';
import { SelfServiceService } from '../modules/self-service/services/self-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hideElement = false;
  isService: boolean = false;
  logginUser: string = '';
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store,
    public selfService: SelfServiceService
  ) {
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/login',
        '/auth/login',
        '/auth',
        '',
        '/auth/recovery-password',
        '/recovery-password',
      ];
      if (event instanceof NavigationEnd) {
        this.hideElement = routes.includes(event.url) ? true : false;
        console.log(event.url);
        if (
          event.url == '/self-service/company' ||
          event.url == '/self-service/gst' ||
          event.url == '/self-service/bank' ||
          event.url == '/self-service/other' ||
          event.url == '/self-service'
        ) {
          this.isService = true;
        } else {
          this.isService = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.authService.onUserUpdate.subscribe((data) => {
      console.log(data)
      this.logginUser = `${data.attributes.first_name} ${data.attributes.last_name}`;
      this.selfService.showLogOut = true;
    });

    if (!this.logginUser) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);

      if (user) {
        if (user.attributes.role === 'customer') {
          this.selfService.showLogOut = true;
        }
        this.logginUser = `${user.attributes.first_name} ${user.attributes.last_name}`;
      }
    } else {
      console.log(this.router.url);
      if (this.router.url === '/self-service') {
        this.selfService.showLogOut = false;
      } else {
        this.selfService.showLogOut = true;
      }
    }
  }

  onLogout(): void {
    const refresh_token: any = localStorage.getItem('refresh_token');
    const id: any = localStorage.getItem('id');
    this.authService.getTokens(refresh_token,id).subscribe((response: any) => {
      this.authService.setTokens(response);
      this.authService.logoutUser().subscribe((res: any) => {
        localStorage.clear();
        this.store.dispatch(new Logout());
        this.router.navigateByUrl('/auth/login');
      });
    });

    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }
  check() {
    this.selfService.showLogOut = false;
    this.onLogout();
    this.selfService.showCheckList = false;
    // this.router.navigateByUrl('/self-service');
    // localStorage.clear();
    // console.log('log off');
  }
  goTo() {
    let userRole = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (userRole?.role === 'customer') {
      this.router.navigateByUrl('/self-service');
      return;
    }
    if (userRole?.role === 'employee') {
      this.router.navigateByUrl('/customer/dashboard');
      return;
    }
    this.router.navigateByUrl('/');
  }
  changePass() {
    this.router.navigateByUrl('/auth/change-password');
  }
}
