import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, fromEvent } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { TimePopupComponent } from './common/components/time-popup/time-popup.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'crm';

  isIdleTimerRequired = false;
  subscription!: Subscription;
  dialogRef: any;

  constructor(
    private router: Router,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        /* 
        auth/recovery-password
        /self-service
        /self-service/company (if local storage has userinfo add timer for logout)
        */
        this.isIdleTimerRequired =
          event.url == '/auth/login' ||
          event.url == '/self-service' ||
          event.url == '/auth/recovery-password' ||
          event.url == '/self-service/company';

        if (!this.isIdleTimerRequired) {
          this.clearSubscription();
          const down$ = fromEvent(document, 'mousedown');
          this.subscription = down$.subscribe((e: any) => {
            this.restart();
          });
          this.restart();
          this.startWatching();
        } else {
          this.stop();
          this.stopWatching();
          this.clearSubscription();
        }
      }
    });
  }

  ngOnInit() {
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count: any) => {
      console.log('onTimerStart');
      this.showTimerPopup(count);
    });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.stop();
      this.stopWatching();
      this.showAlertPopup();
    });
  }

  showTimerPopup(count: any) {
    if (count != null && this.dialogRef === undefined) {
      this.dialogRef = this.dialog.open(TimePopupComponent, {
        width: '450px',
        data: {
          title: 'Timeout',
          message: '',
          type: 'popup',
          disableCancelBtn: false,
          disableOkBtn: false,
        },
        disableClose: true,
        backdropClass: 'loader-backfrop',
        panelClass: 'custom_popup',
      });
    }
    if (
      count != null &&
      this.dialogRef !== undefined &&
      this.dialogRef.componentInstance
    ) {
      let configValue: any = this.userIdle.getConfigValue();
      this.dialogRef.componentInstance.count = configValue.timeout - count;
      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.clickedCancel) {
          this.dialogRef = undefined;
        }
      });
    }
  }

  // Clear storage and navigate to login page
  showAlertPopup() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe((result: any) => {
      //if (result && result.clickedOkay) {
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
      this.dialogRef = undefined;
      // }
    });
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  clearSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
