
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
export function appInitializer(authService: AuthService)
{
    const refresh_token: any = localStorage.getItem('refresh_token');
    const id: any = localStorage.getItem('id');
    console.log(window.location.href);
   const url: any = window.location.href;
    return ()=> new Promise((resolve : any) =>{

    if (refresh_token && refresh_token != '' && refresh_token != 'undefined') {
        authService.getTokens(refresh_token,id).subscribe((response:any) => {
            // const token = {
            //     IdToken: response.id_token,
            //     refresh_token: response.refresh_token
            // }
            authService.setTokens(response);
            authService.startTokenUpdateTimer((response.expires_in / 60) - 5);
            if (url) {
                        resolve(true);
                    }

        }).add(resolve);
    }  else{
        setTimeout(() => {
            resolve('foo');
          }, 300);
    }
   });


}
