import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Subscriber } from 'rxjs/internal/Subscriber';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private LOGIN = 'artfine/eru/login';
  private USER_INFO = 'artfine/eru/userinfo';
  private FETCH_TOKEN = 'artfine/eru/fetchtokens';
  private LOGOUT = 'artfine/eru/logout';

  private Token: string = '';
  private Access_Token: string = '';
  private readonly REFRESH_TOKEN: string = 'refresh_token';
  private User: any;
  onUserUpdate: Subject<any> = new Subject();

  constructor(private http: HttpClient) {}

  loginUser(email: string, password: string) {
    const data = {
      username: email,
      password: password,
    };
    return this.http.post(environment.base_eru_auth_url + this.LOGIN, data);
  }

  fetchUser(access_token: string, id_token: any) {
    const headers = { Authorization: id_token };
    const data = {
      access_token: access_token,
    };
    return this.http.post(
      `${environment.base_eru_auth_url}${this.USER_INFO}`,
      data,
      { headers }
    );
  }

  public get user() {
    return this.User;
  }

  public setUser(user: any) {
    this.User = user;
    this.onUserUpdate.next(user);
  }

  public get token() {
    return this.Token;
  }

  public setTokens(data: any) {
    // console.log('setTokens == > ', data);
    //const refresh_token = data.RefreshToken;
    // localStorage.setItem(this.REFRESH_TOKEN, refresh_token);
    // console.log('setTokens data', data);
    this.Token = data.id_token;
    this.Access_Token = data.access_token;
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem('id', data.id);
    // console.log(' this.Token == > ', this.Token);
  }

  getTokens(refresh_token: string,id:any): Observable<any> {
    const data = {
      refresh_token: refresh_token,
      id:id
    };
    return this.http.post(
      environment.base_eru_auth_url + this.FETCH_TOKEN,
      data
    );
  }

  public refresh_token(minutes: number) {
    return new Observable<any>((subscriber: Subscriber<any>) => {
      interval(1000 * 60 * minutes).subscribe((x: any) => {
        const refresh_token: any = localStorage.getItem(this.REFRESH_TOKEN);
        const id: any = localStorage.getItem('id');
        // console.log('refresh_tok en ==', refresh_token);
        this.getTokens(refresh_token,id).subscribe((response: any) => {
          subscriber.next(response);
        });
      });
    });
  }

  public startTokenUpdateTimer(time: number) {
    this.refresh_token(time).subscribe((data: any) => {
      // const token = {
      //           id_token: data.id_token,
      //           RefreshToken: data.RefreshToken
      //       }
      // console.log('startTokenUpdateTimer', token);
      this.setTokens(data);
    });
  }

  logoutUser() {
    const refresh_token: any = localStorage.getItem('refresh_token');
    const data = {
      refresh_token: refresh_token,
    };
    const headers = { Authorization: this.Token };
    return this.http.delete(`${environment.base_eru_auth_url}${this.LOGOUT}`, {
      headers: headers,
      body: data,
    });
  }
  changePassword(data: any) {
    return this.http.post(
      environment.base_eru_auth_url + 'artfine/eru/changepassword',
      data
    );
  }
  getRecoveryCode(data: any) {
    return this.http.post(
      environment.base_eru_auth_url + `artfine/eru/getrecoverycode${environment.type=="DEVELOPMENT"?'?silent=true':''}`,
      data
    );
  }
  verifyRecoveryCode(data: any) {
    return this.http.post(
      environment.base_eru_auth_url + 'artfine/eru/verifyrecoverycode',
      data
    );
  }
  completeRecovery(data: any) {
    return this.http.post(
      environment.base_eru_auth_url + 'artfine/eru/completerecovery',
      data
    );
  }
}
