<div class="container-fluid" [hidden]="hideElement">
  <div
    class="header-wrapper blue-bg row d-flex align-item"
    style="height: 58px"
  >
    <div
      style="cursor: pointer"
      (click)="goTo()"
      class="col-8 col-md-8 logo f-18 fw-700 white"
    >
      <img class="logo" src="../../assets/images/logo.svg" alt="" />
    </div>
    <div
      class="col-4 col-md-4 d-flex justify-end align-item"
      *ngIf="!isService"
    >
      <div class="bRight"></div>
      <div class="d-flex align-item ml-20">
        <div class="pro_icon">
          <img src="../../assets/images/icons/profile_icon.png" />
        </div>

        <div class="profile">
          <button mat-flat-button [matMenuTriggerFor]="menu">
            {{ logginUser }}
            <img
              class="ml-10"
              src="../../assets/images/icons/down_arrow.svg"
              alt=""
              srcset=""
            />
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="changePass()">
              Change Password
            </button>
            <button mat-menu-item (click)="onLogout()">Log Out</button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="logout" *ngIf="isService && selfService.showLogOut">
      <div
        class="col-4 col-md-4 d-flex justify-end align-item"
        *ngIf="isService && selfService.showLogOut"
      >
        <div class="bRight"></div>
        <div class="d-flex align-item ml-20">
          <div class="pro_icon">
            <img src="../../assets/images/icons/profile_icon.png" />
          </div>

          <div class="profile">
            <button mat-flat-button [matMenuTriggerFor]="menu">
              {{ logginUser }}
              <img
                class="ml-10"
                src="../../assets/images/icons/down_arrow.svg"
                alt=""
                srcset=""
              />
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onLogout()">Log Out</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
