import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.requestToExclude(request.url)) {
      if (!this.authService.token) {
        const refresh_token: any = localStorage.getItem('refresh_token');
        const id: any = localStorage.getItem('id');
        if (refresh_token) {
          this.authService.getTokens(refresh_token,id).subscribe((response) => {
            this.authService.setTokens(response);
          });
        }
      }

      const token = this.authService.token;

      if (token) {
        // If we have a token, we set it to the header
        request = request.clone({
          setHeaders: { Authorization: `${token}` },
        });
      }

      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              //this.showAlertPopup();
            }
          }
        )
      );
    } else if (
      request.url.indexOf('verifyrecoverycode') > 0 ||
      request.url.indexOf('completerecovery') > 0
    ) {
      request = request.clone({
        withCredentials: true,
      });
    }
    return next.handle(request);
  }

  requestToExclude(resquestedUrl: any) {
    if (
      resquestedUrl.indexOf('fetchtokens') > 0 ||
      resquestedUrl.indexOf('login') > 0 ||
      resquestedUrl.indexOf('completerecovery') > 0 ||
      resquestedUrl.indexOf('verifyrecoverycode') > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  // showAlertPopup() {
  //   if (this.dialogRef === undefined) {
  //     this.dialogRef = this.dialogService.showInfoPopup(Messages.ERROR_TITLE,Messages.SESSION_TIMEOUT);

  //     this.dialogRef.afterClosed().subscribe(result => {
  //       if (result && result.clickedOkay) {
  //         this.localStorageService.clearAll();
  //         this.router.navigateByUrl('login');
  //         this.dialogRef = undefined;

  //       }
  //     });
  //   }
  //}
}
