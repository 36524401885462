
<div class="text-center popup_container">
  <div class="timer-container">
    <img class="iconimage" src="../../../../assets/images/icons/Group3keepmelogin.svg" />
    <p class="Paragraph_text Paragraph_text_padding">
      There has been no activity for a long time. <br />
      In <span class="Paragraph_text" [innerHtml]="count"></span> seconds, your session will end and
      you will be logged out
    </p>
  </div>
  <div mat-dialog-actions class="col-md-6 button_margin m-auto">
<button
      class="ml-20"
      mat-button
      cdkFocusInitial
      mat-button
      class="mat-focus-indicator theme-btn primary-btn w-100 mat-button mat-button-base"
      (click)="cancelClickHandle($event)"
    >
      <span class="button_text">Keep me logged in</span>
    </button>
  </div>
</div>

